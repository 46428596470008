<script lang="ts">
    let postId: string = '';
    let postPrice: string = '';
    let postSaleType: string = '';
    let validityAsDate: string = '';

    function openModal(): void {
        // Implement modal opening logic
    }

    function closeModal(): void {
        postId = '';
        postPrice = '';
        postSaleType = '';
        validityAsDate = '';
        // Implement modal closing logic
    }

    function hideSaleModal(): void {
        closeModal();
    }

    function removePost(): void {
        // Implement post removal logic here
        console.log('Removing post');
        closeModal();
    }

    function submitPostForSale(): void {
        // Implement submit for sale logic here
        console.log('Submitting post for sale');
        closeModal();
    }

    function deleteFromMarket(): void {
        // Implement delete from market logic here
        console.log('Deleting post from market');
        closeModal();
    }
</script>

<!-- Post Delete Dialog -->
<div class="modal fade" tabindex="-1" role="dialog" id="post-delete-dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delete post</h5>
                <button type="button" class="close" on:click="{closeModal}" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to delete this post?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning" on:click="{removePost}">Delete</button>
            </div>
        </div>
    </div>
</div>

<!-- Post Sale Modal -->
<div class="modal fade" tabindex="-1" role="dialog" id="post-sale-modal">
    <!-- ... (rest of the modal content) ... -->
</div>
